/**
 * Button
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class Button
 * @param {object} props - Props
 */

import React from "react";
import { Link } from "../link"
import Icon from "../icon"

const getElement = (id) => document.getElementById(id);

class Button extends React.Component {
	render() {
		const { link, label, target, className, color, type, videourl, disabled } = this.props;

		const getId = url => {
			const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
			const match = url.match(regExp);

			return (match && match[2].length === 11)
				? match[2]
				: null;
		}

		const openVideo = event => {

			const videoPlayerClose = document.getElementById("videoPlayer--close");
			const videoWrapper = document.getElementById("videoPlayer--container");

			const embed = videoWrapper.getElementsByTagName('iframe');
			const videoId = getId(videourl);

			embed[0].src = '//www.youtube.com/embed/' + videoId + '?autoplay=1';
			// https://www.youtube.com/watch?v=2LKAUsUbluI

			const mainWrapper = getElement('main')

			mainWrapper.classList.add('video-player-visible');
			videoPlayerClose.addEventListener("click", (e) => {
				embed[0].src = '';
				e.preventDefault();
				e.stopPropagation();
				mainWrapper.classList.remove('video-player-visible');
			});
		}


		if(disabled === true){
			this.props.to = this.props.href = this.props.link = null; //overriding link value
		}
		
		return (
			<Link
				disabled={disabled}
				onClick={!disabled && type === 'video' ? openVideo : null}
				{...this.props}
				to={link}
				target={target}
				className={`button ${className} ${color} ${type === 'video' ? ' video' : ''}`}
			>
				{type === 'compare' ? <span className="i"></span> : null}
				{label}
				{type === 'video' ? <Icon name={'system-play'} color={'white'} /> : null}
				{type === 'arrow' ? <span className="i"></span> : null}
			</Link>
		);
	}

}

export default Button;
